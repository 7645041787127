<template>
  <v-card>
    <v-card-title>New Project</v-card-title>
    <v-form
      method="post"
      @submit.prevent="save"
    >
      <v-row class="pa-5">
        <v-col
          cols="12"
          md="3"
        >
          <label for="name">Name</label>
        </v-col>

        <v-col
          cols="12"
          md="9"
        >
          <v-text-field
            id="name"
            v-model="form.name"
            outlined
            dense
            placeholder="Name"
            hide-details
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <label for="description">Description</label>
        </v-col>

        <v-col
          cols="12"
          md="9"
        >
          <v-textarea
            id="description"
            v-model="form.description"
            outlined
            dense
            placeholder="Description"
            hide-details
          ></v-textarea>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <label for="enabled">Enabled</label>
        </v-col>

        <v-col
          cols="12"
          md="9"
        >
          <v-switch
            v-model="form.enabled"
          ></v-switch>
        </v-col>

        <v-col
          offset-md="3"
          cols="12"
        >
          <v-btn
            color="primary"
            @click="save"
          >
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      form: {
        name: '',
        description: '',
        enabled: '',
      },
    }
  },

  methods: {
    async save() {
      await axios.post('project/add', this.form)
        .then(async response => {
          this.error = null

          await this.$store.dispatch('addAlertMsg', {
            text: 'Project successfully created! Switching to it...'
          })

          await this.$store.dispatch('fetchProjects')
          await this.$store.dispatch('setCurrentProjectById', response.data.id)
          // await this.$router.push({ name: 'dashboard' })

          await this.$router.push({
            name: 'project-page',
            params: {
              id: response.data.id,
            },
          })
        })
        .catch(async error => {
          console.log(error)
          if (error) {
            this.error = error
            Object.values(error.response.data.errors).map(async (msg) => {
              await this.$store.dispatch('addAlertMsg', {
                type: 'error',
                text: msg.join('\n')
              })
            })
          }
        })
    },
  },
}
</script>
